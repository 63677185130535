import React from "react";
import { useLocation } from "react-router-dom";
import Video from "./Video";

const Header = ({ textOne, hero, textTwo, textThree,spanOne,spanTwo }) => {
  const location = useLocation();
  return (
    <div>
      <div className=" wrapper-header">
        {/* {location.pathname === "/" ? (
          <div className="wrapper-video">
            <Video />
          </div>
        ) : (
          <div className="wrapper-hero">
            <img src={hero} alt="hero" className="w-100 img-hero object-fit-cover" />
          </div>
        )} */}
   <div className="wrapper-hero">
            <img src={hero} alt="hero" className="w-100 img-hero object-fit-cover" />
          </div>
        <div className="wrapper-text-header">
          <div
            className={` ${
              location.pathname === "/discover" || location.pathname === '/implement'
                ? "col-lg-9  viet-bold fs-48 text-md-size fs-sm-23" 
                :location.pathname==='/faq'?'col-xl-6 col-lg-5 col-md-7 fs-sm-23 viet-bold fs-48 text-md-size':"col-lg-5 col-md-7 viet-regular fs-48 text-md-size fs-sm-23"
            }`}
          >
            {location.pathname==='/'?<><span className="viet-regular fs-48 text-sm-26 text-md-size">{spanOne}</span> <span className="text-md-size text-sm-26 viet-bold fs-48">{spanTwo}</span></>:textOne}
          </div>
          <div className={`col-lg-9 viet-bold fs-48 text-md-size fs-sm-23`}>{textTwo}</div>
          <div className=" col-lg-7 fs-34 text-md-size-small fs-sm-18">{textThree}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
