import React, { useCallback, useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
const ListFaq = () => {
  const [showComponent, setShowComponent] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
});
const handleShow = (menu) => {
    setShowComponent({
        [menu]: !showComponent[menu],
    })
};
console.log(showComponent)
  return (
    <div>
      <div
        className="wrapper-quest-answ bg-light rounded mt-4 mb-4 p-3"
        onClick={()=>handleShow('one')}
      >
        <div className=" d-flex align-items-center justify-content-between  ">
          <div className="question-list-text mb-2 viet-semi fs-18">
            What is Vlepo?
          </div>
          {showComponent?.one ? (
            <FaAngleDown className="icon-arrow" />
          ) : (
            <FaAngleRight className="icon-arrow" />
          )}
        </div>
        <div className="answer-list viet-regular">
          {showComponent?.one && (
            <div>
              Vlepo is a cloud based Television Hotel Management System (THMS)
              which intergrate all media broadcasts into one dashboard that is
              friendly use for your guest.
            </div>
          )}
        </div>
      </div>
      <div
        className="wrapper-quest-answ bg-light rounded mt-4 mb-4 p-3"
        onClick={()=>handleShow('two')}
      >
        <div className=" d-flex align-items-center justify-content-between  ">
          <div className="question-list-text mb-2 viet-semi fs-18">
          What is a Cloud Based THMS?
          </div>
          {showComponent?.two ? (
            <FaAngleDown className="icon-arrow" />
          ) : (
            <FaAngleRight className="icon-arrow" />
          )}
        </div>
        <div className="answer-list viet-regular">
          {showComponent?.two && (
            <div>
              Television Hotel Management System is a cloud system that manage all TV Android into one dashboard
            </div>
          )}
        </div>
      </div>
      <div
        className="wrapper-quest-answ bg-light rounded mt-4 mb-4 p-3"
        onClick={()=>handleShow('three')}
      >
        <div className=" d-flex align-items-center justify-content-between  ">
          <div className="question-list-text mb-2 viet-semi fs-18">
          How do Cloud Based THMS differ from the traditional Hotel TV System?
          </div>
          {showComponent?.three ? (
            <FaAngleDown className="icon-arrow" />
          ) : (
            <FaAngleRight className="icon-arrow" />
          )}
        </div>
        <div className="answer-list viet-regular">
          {showComponent?.three && (
            <div>
              With our THMS in your grips, you are able to organize all the TV channels under your command. Moreover, you can monetize the TV channels to increase the income of your hotel since Television become a communication media between you and your guest
            </div>
          )}
        </div>
      </div>
      <div
        className="wrapper-quest-answ bg-light rounded mt-4 mb-4 p-3"
        onClick={()=>handleShow('four')}
      >
        <div className=" d-flex align-items-center justify-content-between  ">
          <div className="question-list-text mb-2 viet-semi fs-18">
          Is there any limitation for the device?
          </div>
          {showComponent?.four ? (
            <FaAngleDown className="icon-arrow" />
          ) : (
            <FaAngleRight className="icon-arrow" />
          )}
        </div>
        <div className="answer-list viet-regular">
          {showComponent?.four && (
            <div>
              No, there is not any limitation for the devices to be connected with Vlepo. As long as it's an Android TV then it's time for you to enjoy our Vlepo
            </div>
          )}
        </div>
      </div>
       <div
        className="wrapper-quest-answ bg-light rounded mt-4 mb-4 p-3"
        onClick={()=>handleShow('five')}
      >
        <div className=" d-flex align-items-center justify-content-between  ">
          <div className="question-list-text mb-2 viet-semi fs-18">
          Does Vlepo work with any TV other than Android TV?
          </div>
          {showComponent?.five ? (
            <FaAngleDown className="icon-arrow" />
          ) : (
            <FaAngleRight className="icon-arrow" />
          )}
        </div>
        <div className="answer-list viet-regular">
          {showComponent?.five && (
            <div>
             Vlepo can only be used for Android TV in version 9. However, take it easy. If your device is an analog television then we will provide you an Android Television set box
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListFaq;
