import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Implement from "./pages/Implement";
import Faq from "./pages/Faq";
import Discover from "./pages/Discover";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/implement" element={<Implement />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/discover" element={<Discover />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
