import React from "react";

const FormJoin = () => {
  return (
    <div>
      <div className="viet-bold fs-32 mb-4">Join Vlepo</div>
      <form>
        <select className="form-select mb-3 " aria-label="Default select example">
          <option selected >
            Register / Request Demo
          </option>
          <option value="1">Register</option>
          <option value="2">Request Demo</option>
        </select>
        <div class="mb-3">
          <input
            type="text"
            class="form-control"
            id="name"
            placeholder="Your Name"
          />
        </div>
        <div class="mb-3">
          <input
            type="text"
            class="form-control"
            id="nameCorp"
            placeholder="Company Name"
          />
        </div>
        <div class="mb-3">
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="Business Email"
          />
        </div>
        <div class="mb-3">
          <input
            type="number"
            class="form-control"
            id="phone"
            placeholder="Busisness Phone"
          />
        </div>
        <div class="mb-3">
          <input
            type="text"
            class="form-control"
            id="address"
            placeholder="Address"
          />
        </div>
        <div class="mb-3">
          <input
            type="text"
            class="form-control"
            id="city"
            placeholder="City"
          />
        </div>
      </form>
      <div className="viet-light-italic fs-14 mb-3">
      This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
      </div>
      <button className="mb-3 btn btn-info px-5 rounded-pill text-white">Submit</button>
    </div>
  );
};

export default FormJoin;
