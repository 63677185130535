import React from "react";
import Varnion from "../assets/img/varnion.png";
import { Link } from "react-router-dom";
import { RiFacebookFill, RiInstagramFill } from "react-icons/ri";
import classNames from "classnames";
const Footer = () => {
  return (
    <footer>
      <div className="wrapper-footer py-2 ">
        <div className="padding-container py-2 d-lg-flex align-items-center">
          <div className="col-lg-9 d-flex  justify-content-lg-center ">
            <div className="col-lg-5 col-12 d-flex justify-content-between">
              <div className="menu-footer">
                <div>
                  <Link
                    to={"/"}
                    className="text-decoration-none text-white viet-medium fs-12"
                  >
                    HOME
                  </Link>
                </div>
                <div>
                  <Link
                    to={"/discover"}
                    className="text-decoration-none text-white viet-medium fs-12"
                  >
                    DISCOVER VLEPO
                  </Link>
                </div>
                <div>
                  <Link
                    to={"/implement"}
                    className="text-decoration-none text-white viet-medium fs-12"
                  >
                    IMPLEMENTATION
                  </Link>
                </div>
              </div>
              <div className="menu-footer">
                <div>
                  <Link
                    to={"/faq"}
                    className="text-decoration-none text-white viet-medium fs-12"
                  >
                    FAQ
                  </Link>
                </div>
                <div>
                  <Link
                    to={"/discover"}
                    className="text-decoration-none text-white viet-medium fs-12"
                  >
                    ABOUT VLEPO
                  </Link>
                </div>
                <div>
                  <Link
                    to={"/faq"}
                    className="text-decoration-none text-white viet-medium fs-12"
                  >
                    CONTACT US
                  </Link>
                </div>
              </div>
              <div className="menu-footer d-flex align-items-end">
                <div>
                  <Link
                    to={"/faq"}
                    className="text-decoration-none text-white viet-medium fs-12"
                  >
                    REQUEST DEMO
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 d-flex  justify-content-lg-end pt-md-4 pt-lg-0 pt-4 ">
            <div className="col-lg-10">
              <p className="viet-semi fs-14">PT. Varnion Technology Semesta</p>
              <p>
                <div className="fs-12 viet-semi">Jakarta</div>
                <div className="viet-regular fs-12">
                  Cyber Building 3A Floor Kuningan Barat No. 8 South Jakarta
                  12710 - Indonesia
                </div>
              </p>
              <p>
                <div className="fs-12 viet-semi">Bali</div>
                <div className="viet-regular fs-12">
                  Alamanda Bali 2nd Floor, Unit F No. 67, Jalan By Pass Ngurah
                  Rai, Kedonganan, South Kuta, Badung Regency, Bali
                </div>
              </p>
            </div>
          </div>
        </div>

        <div className="border-top my-2"></div>
        <div className="d-flex  align-items-center justify-content-between padding-container py-2">
          <div className="col-lg-3 ">
            <img src={Varnion} alt="varnion" />
          </div>
          <div className="col-lg-4 col-md-7  d-flex justify-content-end ">
            <div className="padding-container col-12 d-lg-flex d-md-flex justify-content-between align-items-center">
              <div className="viet-regular fs-12">
                Privacy Policy Copyright © 2024 Vlepo, Inc.
              </div>
              <div className="mt-2">
                <Link to={"#"} className="text-black rounded bg-white p-1 me-3">
                  <RiInstagramFill size={20} />
                </Link>
                <Link to={"#"} className="text-black rounded bg-white p-1">
                  <RiFacebookFill size={20} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
