import React, { Component } from "react";
import Slider from "react-slick";
import "/node_modules/slick-carousel/slick/slick.css";
import "/node_modules/slick-carousel/slick/slick-theme.css";
import Slide1 from "../assets/img/bg-slide-1.jpg";
import Slide2 from "../assets/img/bg-slide-2.jpg";
import Slide3 from "../assets/img/bg-slide-3.jpg";
import Slide4 from "../assets/img/bg-slide-4.jpg";
import Slide5 from "../assets/img/bg-slide-5.jpg";

import "../assets/styles/main.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "2%" }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "2% ", zIndex: 999 }} 
      onClick={onClick}
    />
  );
}
export default class Carousel extends Component {
  render() {
    const settings = {
      infinite: true,
      autoplaySpeed:9000,
      speed:2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    return (
      <div className="carousel-container ">
        <Slider {...settings}>
          <div className="slide">
            <div className="img-carosel">
              <img src={Slide1} alt="Image 1" className="w-100 object-fit-cover img-carousel" />
              <div className="caption-left ">
                <div className="caption-header fs-28">Futuristic Guest TV</div>
                <div className="caption-content  fs-18">
                  Feel the experience of streaming live broadcast through
                  International and Local channels
                </div>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="img-carosel">
              <img src={Slide2} alt="Image 1" className="w-100 object-fit-cover img-carousel" />
              <div className="d-flex justify-content-end ">
                <div className="caption-right">
                  <div className="caption-header fs-28 ">
                    Monetize TV Channels
                  </div>
                  <div className="caption-content fs-18  ">
                    Earn and raise your revenue with a touch of digitalization.
                    Manage and monetize your TV channels with vlepo
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="img-carosel">
              <img src={Slide3} alt="Image 1" className="w-100 object-fit-cover img-carousel" />
              <div className="caption-left ">
                <div className="caption-header fs-28">
                  Personal Cast Content
                </div>
                <div className="caption-content  fs-18">
                  Let your guest perceive the exclusiveness with the easy
                  pairing system from their device into the Television
                </div>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="img-carosel">
              <img src={Slide4} alt="Image 1" className="w-100 object-fit-cover img-carousel" />
              <div className="d-flex justify-content-end">
                <div className="caption-right">
                  <div className="caption-header fs-28">
                    Easy Promotion Feature
                  </div>
                  <div className="caption-content fs-18 ">
                    Keen to get attention from your guest? Publish your Hotel
                    promotions and let your guests notice it
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="img-carosel">
              <img src={Slide5} alt="Image 1" className="w-100 object-fit-cover img-carousel" />
              <div className="caption-left-lg ">
                <div className="caption-header fs-28">
                  Provide Content Management System
                </div>
                <div className="caption-content fs-18">
                  Beyond possible, it is only a call away to customize your
                  desired display television. You can add or delete the
                  appropriate channels/applications
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
