import React from "react";
import Vlepo from "../assets/img/vlepo.png";
import MenuList from "./MenuList";
import { HiMiniBars3BottomRight } from "react-icons/hi2";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineHome } from "react-icons/md";

const Navbar = () => {
  const location = useLocation();
  return (
    <div>
      <div className="container-fluid wrapper-navbar sticky-top d-flex align-items-center">
        <div className="d-flex px-lg-5 px-md-5 align-items-center justify-content-between w-100 ">
          <div className="col-xl-2 col-lg-2 col-md-3 col-4  ">
            <Link to="/">
              <img src={Vlepo} alt="vlepo" className=" img-vlepo" />
            </Link>
          </div>
          <div className="d-none d-sm-block col-xl-4 col-lg-5 col-md-6">
            <div className="col-xl-10 d-flex justify-content-around">
              <MenuList link={"/"} nameLink={"Home"} />
              <MenuList link={"/discover"} nameLink={"Discover Vlepo"} />
              <MenuList link={"/implement"} nameLink={"Implementation"} />
            </div>
          </div>
          <div class="col-6 dropdown d-sm-block d-md-none text-end ">
            <div
              class="btn "
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <HiMiniBars3BottomRight className="text-white " size={23}/>
            </div>

            <ul class="dropdown-menu w-100">
              <li>
                <div class="dropdown-item text-sea">
                <MenuList link={"/"} nameLink={"Home"} classname={'text-sea'} />
                </div>
              </li>
              <li>
                <div class="dropdown-item text-sea">
                <MenuList link={"/discover"} nameLink={"Discover Vlepo"} classname={'text-sea'} />
                </div>
              </li>
              <li>
                <div class="dropdown-item text-sea">
                <MenuList link={"/implement"} nameLink={"Implementation"} classname={'text-sea'} />

                </div>
              </li>
            </ul>
          </div>
          <div className="d-none d-sm-block col-xl-6 col-lg-5 col-md-3 text-end">
            {location.pathname !== "/faq" ? (
              <Link to="/faq">
                <button className="rounded-pill btn px-lg-4 px-md-3  btn-light text-info viet-medium fs-15">
                  Join Vlepo
                </button>
              </Link>
            ) : (
              <Link to={"/"}>
                <MdOutlineHome size={30} className="text-white" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
