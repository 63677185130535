import React from "react";
import Layout from "../Layout";
import Hero from "../assets/img/header1.jpg";
import Carousel from "../components/Carosel";
import Room from "../assets/img/page1-1room.png";
import Message from "../assets/img/page1-2message.png";
import Hello from "../assets/img/page1-3hello.png";
import Demo from "../components/Demo";

const Home = () => {
  return (
    <>
      <div className="wrapper-home">
        <div className="padding-container">
          <div className="text-center mt-4 mb-3">
            <div className="viet-bold text-sm-26 fs-36">Different than the Others </div>
            <div className="viet-regular fs-22 fs-sm-20">
              We are TV Interactive Solution
            </div>
          </div>
          <Carousel />
        </div>
        <div className="wrapper-control">
          <div className="wrapper-text-control text-center mb-5 px-2">
            <div className="viet-bold fs-36 text-sm-26">
              It’s time for Vlepo to Take Control!
            </div>
            <div className="viet-regular fs-22 fs-sm-20">
              Enhance your Hotel Management System that ease and spoil your
              guests through various
            </div>
            <div className="viet-regular fs-22 fs-sm-20">
              features from Vlepo powered by Varnion
            </div>
          </div>
          <div className="container-fluid list-control bg-dark-grey py-5">
            <div className="d-lg-flex d-md-flex align-items-center  align-items-md-baseline ">
              <div className="col-lg-4 col-md-4 col-sm-12 text-center text-white p-0 mb-4 mb-lg-0 mb-md-0">
                <img src={Room} alt="room" width={60} className="mb-2"  />
                <div className="border border-info line-home"></div>
                <div className="viet-semi fs-22">Personal In Room Message</div>
                <div className="viet-regular fs-15">
                  Bring more satisfying guests by sending the
                </div>
                <div className="viet-regular fs-15">
                  beautifully <i>unexpected</i> personal inRoom message
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-white text-center p-0 mb-4">
                <img src={Message} alt="room" width={58} className="mb-2" />
                <div className="border border-info line-home"></div>
                <div className="viet-semi fs-22">Broadcast Announcement</div>
                <div className="viet-regular fs-15">
                  Sending urgent message or promoting your hotel
                </div>
                <div className="viet-regular fs-15">
                  instantly with less complexity
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-white text-center p-0">
                <img src={Hello} alt="room" width={75} className="mb-2"   />
                <div className="border border-info line-home"></div>
                <div className="viet-semi fs-22">Welcome Landing Screen</div>
                <div className="viet-regular fs-15">
                  A warm welcome to a memorable adventure begins
                </div>
                <div className="viet-regular fs-15">
                  at the first moment your guests arrived
                </div>
              </div>
            </div>
          </div>
        </div>
        <Demo/>
      </div>
    </>
  );
};
const HomeWithLayout = () => {
  return (
    <Layout
      title="Home"
      content={<Home />}
      hero={Hero}
      spanOne={'Welcome to the '}spanTwo ={'New Era of TV Hospitality'}
    />
  );
};

export default HomeWithLayout;