import React from "react";
import Layout from "../Layout";
import Hero from "../assets/img/header1.jpg";
import Image2 from "../assets/img/page2-1.png";
import Feature from "../components/Feature";
import CarouselDiscover from "../components/CarouselDiscover";
import "../assets/styles/main.css";
import Demo from "../components/Demo";
const Discover = () => {
  return (
    <div>
      <div className="wrapper-formula text-center">
        <div className="padding-container mt-lg-5 mt-3">
          <div className="text-center">
            <div className="viet-bold fs-36 text-sm-26">
              Guest Hotel Formula
            </div>
            <div className="text-center">
              <div className="viet-regular fs-22 fs-sm-20">
                Embrace the latest breakthrough from Vlepo that will create a
                perfect solace of comfort
              </div>
              <div className="viet-regular fs-22">
                for your guest and form an effective line of communication
              </div>
            </div>
          </div>
          <div className="wrapper-img-formula mt-3">
            <img src={Image2} alt="image1" className="img-overlay w-100 rounded" />
            <div className="text-overlay ">
              <div className="text-overlay-content fs-36 viet-semi text-start fs-sm-20">
                “Further, it Helps You to <br /> Analyze Your Guest Preference”
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-light-grey py-4">
          <div className="mb-3">
            <div className="viet-bold fs-36 text-sm-26">Main Features</div>
            <div className="viet-regular fs-22 fs-sm-20">
              The latest interactive content and display that can be customized
              personally
            </div>
            <div className="viet-regular fs-22 fs-sm-20">
              following your guest preferences:
            </div>
          </div>
          <div className="padding-container">
            <Feature />
          </div>
        </div>
        <div className="padding-container">
          <div className="text-center my-4">
            <div className="viet-bold fs-36 text-sm-26">
              Advanced Multi Tenancy Dashboard
            </div>
            <div className="viet-regular fs-22 fs-sm-20">
              The exclusive and friendly dashboard that enable you to monitor
              all
            </div>
            <div className="viet-regular fs-22 fs-sm-20">
              available TV under one group hotel management.
            </div>
          </div>
          <CarouselDiscover />
        </div>
        <Demo />
      </div>
    </div>
  );
};
const DiscoverWithLayout = () => {
  return (
    <Layout
      title="Discover"
      content={<Discover />}
      hero={Hero}
      textOne={`A Remarkable Experience Chimes In`}
      textTwo={"with the Remarkable Touch of Innovations"}
      textThree={"Discover our advanced features here"}
    />
  );
};

export default DiscoverWithLayout;
