import React from 'react'
import Whatsapp from '../assets/img/wa.png'


const Contact = () => {
  return (
    <div className='text-center my-5'>
        <div className="viet-bold fs-28  text-sm-26">Contact Us for Further Information</div>
        <div className="viet-regular fs-18">We are beyond ready to asisst you!</div>
        <button className='btn border my-3'>
            <div className="viet-semi fs-24 d-flex align-items-center">
                <img src={Whatsapp} alt="whatsapp" /> 
                <div className="ms-2">Contact Us</div>
                </div>
        </button>
    </div>
  )
}

export default Contact