import React, { Component } from "react";
import Slider from "react-slick";
import "/node_modules/slick-carousel/slick/slick.css";
import "/node_modules/slick-carousel/slick/slick-theme.css";
import Image1 from "../assets/img/page2-2.jpg";
import Image2 from "../assets/img/page2-3.jpg";
import Image3 from "../assets/img/page2-4.jpg";

import "../assets/styles/main.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "2%" }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "2% ", zIndex: 999 }}
      onClick={onClick}
    />
  );
}
export default class Carousel extends Component {
  render() {
    const settings = {
      // dots: true,
      infinite: true,
      autoplaySpeed:9000,
      speed:2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    return (
      <div className="carousel-container">
        <Slider {...settings}>
          <div className="slide-discover">
            <div className="img-carosel">
              <img
                src={Image1}
                alt="Image 1"
                className="w-100 object-fit-cover img-carousel-discover"
              />
              <div className="caption-discover text-start text-black">
                <div className="caption-discover-header fs-28  viet-semi fs-sm-18 fs-md-26 ">
                  Analytic Dashboard TV on/off
                </div>
                <div className="viet-regular w-100 fs-18 fs-sm-15 fs-md-20">
                  Now you can maintain green energy at your site by
                  switching remotely the inactive TV through analytic
                  dashboard
                </div>
              </div>
            </div>
          </div>
          <div className="slide-discover">
            <div className="img-carosel">
              <img
                src={Image2}
                alt="Image 1"
                className="w-100 object-fit-cover img-carousel-discover"
              />
              <div className="caption-discover-left text-end  text-black">
                <div className="caption-discover-header fs-28  viet-semi fs-sm-18 fs-md-26">
                  Content Management System
                </div>
                <div className="viet-regular w-100 fs-18 fs-sm-15 fs-md-20">
                  Manage the welcome screen and modify every menu
                  
                  by adding or deleting particular content in order to
                   adjust with your guest request
                </div>
              </div>
            </div>
          </div>
          <div className="slide-discover">
            <div className="img-carosel">
              <img
                src={Image3}
                alt="Image 1"
                className="w-100 object-fit-cover img-carousel-discover"
              />
              <div className="caption-discover-left text-end  text-black">
                <div className="caption-discover-header fs-28  viet-semi fs-sm-18 fs-md-26">
                  Broadcast Annoucement
                </div>
                <div className="viet-regular w-100 fs-18 fs-sm-15 fs-md-20">
                  Do you have an urgent or a special message for your guest?
                  
                  Broadcast it to all TV or to paricular TV
                   There is even a panic button in case of an emergency
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
