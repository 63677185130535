import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./components/Navbar";
import "./assets/styles/main.css";
import "./assets/styles/app.css";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default function Layout({
  title,
  content,
  textOne,
  textTwo,
  textThree,
  hero,
  spanOne,
  spanTwo,
}) {
  return (
    <>
      <Helmet titleTemplate="%s | Vlepo" title={title}>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap"
          rel="stylesheet"
        />
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div>
        <Navbar />
        <Header
          hero={hero}
          spanOne={spanOne}
          spanTwo={spanTwo}
          textOne={textOne}
          textTwo={textTwo}
          textThree={textThree}
        />
        {content}
        <Footer />
      </div>
    </>
  );
}
