import React from "react";
import { Link } from 'react-router-dom'

const Demo = () => {
  return (
    <div>
      <div className="padding-container text-center py-5">
        <div className="viet-bold fs-36 text-sm-26">With Us on Your Grips</div>
        <div className="viet-regular fs-22 fs-sm-20 px-2">
        Delivering a Great Television Experience to Your Guest is No Longer a
          Dream
        </div>
         <Link to='/faq' className="text-decoration-none">
        <button className="rounded-pill btn btn-info text-white my-3 px-4 py-2 shadow">
          Request a Demo
        </button>
         </Link>
      </div>
    </div>
  );
};

export default Demo;
