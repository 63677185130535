import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const MenuList = ({link,nameLink,classname}) => {
    const location = useLocation();

    // console.log(location.pathname);
// console.log(location!==link)
 
  return (
   <Link to={link} className={`text-decoration-none ${classname} ${location.pathname==link?'text-sea':'text-white-15'}`}>{nameLink}</Link>
  )
}

export default MenuList