import Image1 from './img/page21.png'
import Image2 from './img/page22.png'
import Image3 from './img/page23.png'
import Image4 from './img/page24.png'
import Image5 from './img/page25.png'
import Image6 from './img/page26.png'
import Image7 from './img/page27.png'
import Image8 from './img/page28.png'

const data=[
    {image:Image1,text:'In Room Dining Video & Picture gallery'},
    {image:Image2,text:'News or Ads Directory'},
    {image:Image3,text:'Weather Information'},
    {image:Image4,text:'Emergency Broadcast Announcement'},
    {image:Image5,text:'Local TV Broadcast'},
    {image:Image6,text:'Personal in Room Message'},
    {image:Image7,text:'Welcome Landing Screen',text2:'(real-time customisation)'},
    {image:Image8,text:'Broadcasting your own content'},
]

export default data;