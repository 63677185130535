import React from "react";
import Layout from "../Layout";
import Hero from "../assets/img/header3.png";
import Image from "../assets/img/page3-1.jpg";
import Image2 from "../assets/img/page3-2.jpg";
import Image3 from "../assets/img/page3-3.png";
import Image4 from "../assets/img/page3-4.jpg";
import Image5 from "../assets/img/page3-5.jpg";
import { Link } from "react-router-dom";

const Implement = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="padding-container py-4 wrapper-implement">
          <div className="wrapper-hero-implement">
            <img src={Image} alt="image" className="w-100 rounded hero-implement object-fit-cover" />
          </div>
          <div className="text-hero text-ready">
            <div className="fs-36 viet-bold fs-sm-18">Are you Ready?</div>
            <div className="fs-22 viet-regular fs-sm-15">
              Prepare your hotel information and the email used
            </div>
            <div className="fs-22 viet-regular fs-sm-15">
              as administrator for all of your Android TV’s
            </div>
          </div>
        </div>
        <div className="padding-container">
          <div className="row ps-2 px-md-2">
            <div className="col-lg-7 bg-light-grey text-end rounded px-4 py-md-3 py-3 d-flex align-items-center justify-content-center">
              <div className="w-75">
                <div className="viet-bold fs-36 text-sm-26">As Simple As</div>
                <div className="d-flex justify-content-end">
                  <div className="viet-regular fs-22 fs-sm-20">
                    Fill the required installation request form with accurate
                    and valid Hotel information
                  </div>
                </div>
                <Link to="/faq">
                  <button className="btn btn-info  rounded-pill px-3 text-white viet-regular mt-lg-5 mt-md3- mt-3">
                    Request a Demo
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-lg-5 d-flex justify-content-center ps-lg-3 ps-0 mt-md-3 mt-lg-0 pe-0">
              <img src={Image2} alt="image2" className="w-100 rounded" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light-grey my-5 py-5">
        <div className="padding-container">
          <div className="text-header-implement text-center mb-4">
            <div className="viet-bold fs-36 text-sm-26">You’re in a Good Hands</div>
            <div className="viet-regular fs-22 fs-sm-20">
              If your submitted hotel information are valid, our team will
              promptly conduct
              <br /> a survey at your hotel
            </div>
          </div>
          <div className="img-hero">
            <img src={Image3} alt="image3" className="w-100 rounded img-size object-fit-cover" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="padding-container flex-wrap align-items-stretch row">
          <div className="col-lg-5 img-hero-small ps-0 pe-0 pe-lg-3 ">
            <img src={Image4} alt="image2" className="w-100  rounded" />
          </div>
          <div className="col-lg-7 bg-light-grey rounded px-lg-4 mt-lg-0 mt-md-3 py-md-3 py-3 d-flex align-items-center justify-content-center">
            <div className="w-75">
              <div className="viet-bold fs-36 text-sm-26">Almost there!</div>
              <div className="viet-regular fs-22 fs-sm-20">
                Once our team has surveyed your hotel, there will be a
                subscription agreement signifying the mutual agreement between
                both parties
              </div>
              <Link to="/faq">
                <button className="btn btn-info rounded-pill px-3 text-white viet-regular mt-lg-5 mt-md-3 mt-3">
                  Join Vlepo
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="padding-container my-5">
          <div className="text-header-implement text-center mb-4">
            <div className="viet-bold fs-36 text-implement text-sm-26">All Done!</div>
            <div className="viet-bold fs-36 text-implement text-sm-26">
              Your Hotel is Ready for Vlepo’s Installation
            </div>
            <div className="viet-regular fs-22 fs-sm-20">
              After installation is complete, you will receive an account from
              our admin
            </div>
          </div>
          <div>
            <img src={Image5} alt="image5" className="w-100 rounded" />
          </div>
        </div>
      </div>
    </>
  );
};
const ImplementWithLayout = () => {
  return (
    <Layout
      title="Implement"
      content={<Implement />}
      hero={Hero}
      textOne={"Take a Peek of How Magical our Vlepo is"}
      textThree={" Just One Click Away From Our Team"}
    />
  );
};

export default ImplementWithLayout;
