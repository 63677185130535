import React, { useEffect, useState } from "react";
import data from "../assets/dataFeatures";
import Bgrey from "../assets/img/bg.png";
import Table from "../assets/img/table.png";
const Feature = () => {
  const [showAll, setShowAll] = useState(false);
  const [dataFeature,setDataFeature]=useState([])
  const handleShow = () => {
    setShowAll(!showAll);
  };
  useEffect(() => {
    if(!showAll){
      setDataFeature(data.slice(0,3))
    }
    else{
      setDataFeature(data)
    }
    
  }, [showAll])
  return (
    <div>
      <div className="d-flex flex-wrap align-items-baseline ">
        {dataFeature.map((item, index) => (
          <div key={index} className="col-lg-4 my-3">
            <div className="wrapper-bg">
              <img src={Bgrey} alt="bg" className="w-75 h-50" />
              <img src={Table} alt="table" className="overlay-img w-65" />
            </div>
            <img src={item.image} alt={"logo"} className="logo-overlay" />
            <div className="viet-medium fs-22text-center row justify-content-center">
              <div className="col-lg-8 viet-medium fs-22">{item.text}</div>
              {item.text2 && (
                <div className="fs-18 viet-regular">{item.text2}</div>
              )}
            </div>
          </div>
        ))}
      </div>
      {!showAll ? (
        <div className="text-center">
          <button
            className="btn border-black viet-regular fs-18 rounded-pill px-4"
            onClick={handleShow}
          >
            View All
          </button>
        </div>
      ) : (
        <div className="text-center">
          <button
            className="btn border-black viet-regular fs-18 rounded-pill px-4"
            onClick={handleShow}
          >
            Show Less
          </button>
        </div>
      )}
    </div>
  );
};

export default Feature;
