import React from "react";
import Layout from "../Layout";
import Hero from "../assets/img/header4.png";
import FormJoin from "../components/FormJoin";
import Image from "../assets/img/faq.png";
import ListFaq from "../components/ListFaq";
import Contact from "../components/Contact";

const Faq = () => {
  return (
    <div>
      <div className="formJoin container shadow rounded bg-white mb-5 p-5">
        <FormJoin />
      </div>
      <div className="img-faq">
        <img src={Image} alt="imgae-faq" className="w-100" />
        <div className="text-overlay">
          <div className="viet-semi fs-36 fs-sm-20">Frequently Asked Question</div>
          <div className="viet-regular fs-22 fs-sm-18">
            Beyond Ready to Answer Your Curiousity Here!
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="faq">
          <div className="viet-bold fs-28 text-sm-26">Frequently Asked Question (FAQ)</div>
          <ListFaq />
        </div>
        <Contact/>
      </div>
    </div>
  );
};
const FaqWithLayout = () => {
  return (
    <Layout
      title="Faq"
      content={<Faq />}
      hero={Hero}
      textOne={"Unlock a Remarkable Hospitality TV with VLEPO"}
      textThree={"Register Now"}
    />
  );
};

export default FaqWithLayout;
